<template>
<div>
  <vs-avatar
    class="border-2 border-solid border-white"
    :src="require(`@/assets/images/portrait/icons/${params.data.channel}`)"
  />
</div>
</template>

<script>
    export default {
        name: 'CellRendererChannel'
    }
</script>
