<template>
  <div>
    <vs-button
      radius
      type="flat"
      color="dark"
      icon-pack="feather"
      icon="icon-eye"
      class="mt-1"
      @click="viewRecord()"
    />
    <vs-sidebar
      parent="#sidebar-contact"
      :hidden-background="true"
      v-model="sidebar"
      :position-right="true"
      :click-not-close="true"
      id="contact-profile-sidebar"
    >
      <contact-profile
        v-if="contact._id"
        :conversationID="
          contact.inboxConversationData
            ? contact.inboxConversationData._id
            : null
        "
        :contactTags="contact.tags"
        :contactName="contact.name"
        :contactEmail="contact.email"
        :contactPhone="contact.phone"
        :contactPhase="contact.phase"
        :contactProfilePic="contact.profilePic"
        :contactID="contact._id"
        @close-profile-sidebar="closeSidebar()"
        @update-contact="updateContact"
        :status="contact.status"
        :activeContact="contact._id"
        :phaseList="$store.state.contact.phase"
      />
    </vs-sidebar>
  </div>
</template>

<script>
import ContactProfile from "@/components/duotalk-contact-profile/ContactProfile.vue";

export default {
  data() {
    return {
      sidebar: false,
    };
  },
  computed: {
    contact() {
      return this.params.data;
    },
    userImg() {
      return "user.png";
    },
  },
  methods: {
    viewRecord() {
      this.sidebar = true;
    },
    closeSidebar() {
      this.sidebar = false;
    },
    updateContact(update) {
      let contacts = this.$store.state.contact.contactsPhase;
      let index = contacts.findIndex((el) => el._id === update._id);
      update.index = index;
      this.$store.dispatch("contact/updateContactProp", update).then(() => {
        this.$store.dispatch("contact/refreshContact", {
          index: index,
          contactRef: "table",
        });
      });
    },
  },
  components: {
    ContactProfile,
  },
};
</script>
<style scoped>
.header-sidebar {
  padding-top: 0.5rem !important;
}
#about-card .vx-card__body {
  padding: 0 !important;
}
</style>`
