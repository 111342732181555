<template>
  <div>
    <div class="flex mt-2">
      <div v-for="(tag, index) in params.tags" :key="index">
        <vx-tooltip color="primary" :title="tag.label | capitalize" :text="tag.value | capitalize">
          <vs-chip style="height: 30px">
            <span :class="{ truncate: params.tags.length > 2 }">
              {{ tag.label | capitalize }}: {{ tag.value | capitalize }}
            </span>
          </vs-chip>
        </vx-tooltip>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CellRendererTags"
};
</script>

<style lang="scss" scoped>
.truncate {
  width: 50px;
  padding-left: 2px;
  padding-right: 2px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
