<template>
  <div id="sidebar-contact">
    <div>
      <h3 class="mb-5 mt-10">
        {{ $t("Contacts") }}
      </h3>
    </div>
    <vs-card class="mb-6">
      <div class="flex flex-wrap justify-between px-6 py-4" slot="header">
        <div class="w-2/3 flex">
          <div class="w-full md:w-1/3 md:mr-3">
            <vs-input
              icon="icon-search"
              icon-pack="feather"
              class="w-full"
              placeholder="Nome, Email ou Telefone do Contato"
              label="Buscar contato"
              v-model="nameFilter"
              @keyup.enter="getContacts"
            />
          </div>
          <div
            v-if="
              $store.state.user.userRole === 'admin' ||
              $store.state.user.userRole === 'manager' ||
              $store.state.user.userRole === 'master'
            "
          >
            <vs-button
              type="border"
              icon-pack="feather"
              icon="icon-upload"
              class="mt-6 px-2"
              icon-after
              :to="{ name: 'import-contacts' }"
            >
              Importar contatos
            </vs-button>
          </div>
        </div>
        <div class="w-1/3 flex-col items-center justify-end">
          <div class="flex items-center gap-4">
            <vs-select
              icon="icon-calendar"
              icon-pack="feather"
              :label="$t('Year')"
              placeholder="Filtre por data"
              v-model="currentYearIndex"
              width="70%"
            >
              <vs-select-item
                :key="index"
                :value="item === 'Todos' ? null : index"
                :text="item"
                v-for="(item, index) in years"
              />
            </vs-select>
            <vs-select
              icon="icon-calendar"
              icon-pack="feather"
              placeholder="Filtre por data"
              v-model="monthFilter"
              :label="$t('Month')"
              :disabled="currentYearIndex == null"
              width="70%"
            >
              <vs-select-item
                :key="index"
                :value="item === 'Todos' ? null : index"
                :text="item"
                v-for="(item, index) in monthNames"
              />
            </vs-select>
            <vs-button
              color="success"
              type="filled"
              class="mt-6 px-1 w-full"
              @click="downloadCSV()"
              :disabled="disabledDateAndCsv"
              v-if="$acl.check('admin')"
            >
              {{ $t("DownloadCSV") }}
            </vs-button>
          </div>
          <vs-alert
            icon-pack="feather"
            icon="icon-info"
            class="mt-3"
            color="warning"
            v-if="$acl.check('admin') && disabledDateAndCsv"
          >
            Download CSV disponível apenas com o filtro mensal
          </vs-alert>
        </div>
      </div>
    </vs-card>
    <vs-card>
      <div class="flex justify-end items-center gap-3">
        <button
          @click="
            blocked = false;
            optOut = !optOut;
            showContact = optOut ? 'table' : 'board';
          "
          class="vs-component vs-button px-1 rounded-4xl vs-button-dark includeIcon"
          :class="{ 'vs-button-border': !optOut, 'vs-button-filled': optOut }"
        >
          <span class="px-2">Opt-out</span>
        </button>
        <button
          @click="
            optOut = false;
            blocked = !blocked;
            showContact = blocked ? 'table' : 'board';
          "
          class="vs-component vs-button px-1 rounded-4xl vs-button-danger includeIcon"
          :class="{ 'vs-button-border': !blocked, 'vs-button-filled': blocked }"
        >
          <span class="vs-button-text vs-button--text pl-1">Bloqueados</span>
          <i
            class="vs-icon notranslate icon-scale vs-button--icon feather icon-user-x null ml-3 pr-1"
          ></i>
        </button>
        <vs-button
          v-if="showContact === 'table'"
          @click="
            showContact = 'board';
            blocked = false;
            optOut = false;
          "
          type="flat"
          radius
          color="primary"
        >
          <feather-icon icon="TrelloIcon" svgClasses="w-5 h-5 text-primary" />
        </vs-button>
        <vs-button
          v-if="showContact === 'board'"
          @click="showContact = 'table'"
          icon-pack="feather"
          icon="icon-list"
          color="primary"
          radius
          type="flat"
        />
        <vs-dropdown
          vs-trigger-click
          vs-custom-content
          class="hover:bg-primary-light rounded-full cursor-pointer"
        >
          <div class="p-3 flex justify-center items-center">
            <feather-icon
              icon="ColumnsIcon"
              svgClasses="w-5 h-5 text-primary"
            />
          </div>
          <vs-dropdown-menu class="w-1/6">
            <div class="p-4">
              <h5 class="mb-2">Colunas Visíveis:</h5>
              <div class="flex flex-wrap">
                <div class="w-full flex mb-3">
                  <vs-switch v-model="phone" />
                  <label class="text-md opacity-75 ml-2">Telefone</label>
                </div>
                <div class="w-full flex mb-3">
                  <vs-switch v-model="email" />
                  <label class="text-md opacity-75 ml-2">Email</label>
                </div>
                <div class="w-full flex mb-3">
                  <vs-switch v-model="date" />
                  <label class="text-md opacity-75 ml-2">Data</label>
                </div>
                <div class="w-full flex">
                  <vs-switch v-model="tags" />
                  <label class="text-md opacity-75 ml-2">Tags</label>
                </div>
              </div>
            </div>
            <div class="p-4" v-if="phaseFilter.value === 'all'">
              <h5 class="mb-2">Status Visíveis:</h5>
              <div class="flex flex-wrap">
                <div
                  v-for="phaseContact in phase"
                  :key="`status_${phaseContact}`"
                  class="w-full flex mb-3"
                >
                  <vs-switch
                    :vs-value="phaseContact"
                    v-model="visiblePhasesOptions"
                  />
                  <label class="text-md opacity-75 ml-2">{{
                    phaseContact
                  }}</label>
                </div>
              </div>
            </div>
          </vs-dropdown-menu>
        </vs-dropdown>
      </div>
      <div v-if="showContact === 'board'">
        <div class="flex justify-center">
          <div id="board-card-wrapper" class="flex flex-col overflow-x-scroll">
            <div id="board-header" class="flex">
              <div
                v-for="phaseContact in phase"
                :key="phaseContact"
                :class="{ hidden: visiblePhases.indexOf(phaseContact) === -1 }"
              >
                <div
                  v-if="
                    phaseFilter.value === 'all' ||
                    phaseFilter.value === phaseContact
                  "
                  class="text-gray-700 font-semibold font-sans tracking-wide text-base ml-4 flex-1"
                >
                  {{ phaseContact }}
                  <span class="count-board" v-if="countBoard[phaseContact] > 0">
                    {{ getCountBoard(countBoard[phaseContact]) }}
                  </span>
                </div>
              </div>
            </div>
            <div
              class="min-h-screen w-full flex py-5 space-y-2"
              id="board-card"
            >
              <div v-for="(phaseContact, index) in phase" :key="index">
                <div
                  class="bg-gray-200 rounded-lg px-3 py-3 column-width rounded mr-4 mb-5"
                  style="background-color: #f7fafc"
                  v-if="
                    (phaseFilter.value === 'all' ||
                      phaseFilter.value === phaseContact) &&
                    visiblePhases.indexOf(phaseContact) > -1
                  "
                >
                  <!-- Draggable component comes from vuedraggable. It provides drag & drop functionality -->
                  <cell-renderer-board
                    ref="boardCol"
                    :visible-phases="visiblePhases"
                    :fieldsContact="fieldsBoard"
                    :phaseContact="phaseContact"
                    :typeContact="typeFilter.value"
                    :tagsContact="tagsFilter"
                    :monthFilter="monthFilter"
                    :emailOrPhoneFilter="nameFilter"
                    :nameFilter="nameFilter"
                    @reset-search="resetSearch"
                    @sidebar="showSidebar($event)"
                    @updateCountBoard="updateCountBoard($event)"
                    @setShowLoadMoreContacts="setShowLoadMoreContacts"
                    @setLoadMoreContactsLoading="setLoadMoreContactsLoading"
                  />
                </div>
              </div>
            </div>
            <div
              v-if="showLoadMoreContacts"
              class="w-full text-center bottom-0 absolute"
              style="margin-bottom: 30px"
            >
              <div v-if="loadMoreContactsLoading">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  style="margin: auto; display: block; shape-rendering: auto"
                  width="70px"
                  height="70px"
                  viewBox="0 0 100 100"
                  preserveAspectRatio="xMidYMid"
                >
                  <circle
                    cx="50"
                    cy="50"
                    r="32"
                    stroke-width="8"
                    stroke="#bebebe"
                    stroke-dasharray="50.26548245743669 50.26548245743669"
                    fill="none"
                    stroke-linecap="round"
                  >
                    <animateTransform
                      attributeName="transform"
                      type="rotate"
                      repeatCount="indefinite"
                      dur="1s"
                      keyTimes="0;1"
                      values="0 50 50;360 50 50"
                    ></animateTransform>
                  </circle>
                </svg>
              </div>
              <vs-button
                v-else
                class="my-2 shadow-lg"
                type="filled"
                color="white"
                style="border-radius: 30px; color: #424242"
                @click="loadMoreContacts"
              >
                Ver mais</vs-button
              >
            </div>
          </div>
        </div>
      </div>
      <ag-grid-vue
        v-if="showContact === 'table'"
        ref="agGridTable"
        :gridOptions="gridOptions"
        class="ag-theme-material w-100 ag-grid-table"
        :columnDefs="columnDefs"
        :defaultColDef="defaultColDef"
        :rowData="contacts"
        rowSelection="multiple"
        colResizeDefault="shift"
        :animateRows="true"
        :floatingFilter="false"
        :enableRtl="$vs.rtl"
        :localeText="localeText"
        :paginationAutoPageSize="true"
        :pagination="true"
        :overlayLoadingTemplate="overlayLoadingTemplate"
      />
    </vs-card>
    <vs-sidebar
      parent="#sidebar-contact"
      :hidden-background="true"
      v-model="sidebar"
      :position-right="true"
      :click-not-close="true"
    >
      <contact-profile
        v-if="contact._id"
        :conversationID="
          contact.inboxConversationData
            ? contact.inboxConversationData._id
            : null
        "
        :contactName="contact.name"
        :contactEmail="contact.email"
        :contactPhone="contact.phone"
        :contactPhase="contact.phase"
        :contactProfilePic="contact.profilePic"
        :contactOptIn="contact.optIn"
        :phaseList="phase"
        :contactID="contact._id"
        :activeContact="contact._id"
        @init-add-conversation-popup="initAddConversationPopup"
        @close-profile-sidebar="closeSidebar()"
        @update-contact="updateContact"
        :status="contact.status"
        updateStatusContact="update"
      />
    </vs-sidebar>
  </div>
</template>
<script>
import "@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss";
// Cell Renderer
import CellRendererChannel from "./cell-renderer/CellRendererChannel.vue";
import CellRendererActions from "./cell-renderer/CellRendererActions.vue";
import CellRendererTags from "./cell-renderer/CellRendererTags.vue";
import CellRendererBoard from "./cell-renderer/CellRendererBoard.vue";
import ContactProfile from "@/components/duotalk-contact-profile/ContactProfile.vue";
import localeTextPT_br from "@/store/leads-management/localeText.PT-BR.js";
import vSelect from "vue-select";
import { mapState } from "vuex";
import Callback from "../../Callback.vue";
import { AgGridVue } from "ag-grid-vue";
import normalizeString from "@/helpers/normalizeString";

export default {
  name: "Contacts",
  components: {
    "ag-grid-vue": AgGridVue,
    /* eslint-disable vue/no-unused-components */
    CellRendererChannel,
    CellRendererActions,
    CellRendererTags,
    CellRendererBoard,
    vSelect,
    ContactProfile,
    Callback,
  },
  data() {
    return {
      optOut: false,
      overlayLoadingTemplate: null,
      boardSearch: false,
      visiblePhases: [],
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.6,
        suppressScrollY: true,
      },
      countBoard: {},
      typeFilterBoard: "all",
      contactsBoard: {},
      limit: 30,
      currentPage: 1,
      active: false,
      contact: {},
      sidebar: false,
      fieldsBoard: ["view", "name", "type", "date", "status", "tags"],
      showContact: "board",
      channel: true,
      phone: false,
      email: false,
      type: true,
      date: true,
      status: true,
      tags: true,
      nameFilter: "",
      emailOrPhoneFilter: "",
      phaseFilter: { label: this.$t("All"), value: "all" },
      typeFilter: { label: this.$t("All"), value: "all" },
      tagsFilter: "",
      typeOptions: [
        { label: "Empresa", value: "Empresa" },
        { label: "Pessoa", value: "Pessoa" },
        { label: this.$t("All"), value: "all" },
      ],
      gridOptions: {},
      gridApi: null,
      defaultColDef: {
        sortable: true,
        resizable: true,
        suppressMenu: true,
      },
      visibleColumns: [
        "view",
        "name",
        "email",
        "phone",
        "type",
        "date",
        "status",
        "tags",
      ],
      columnDefs: [
        {
          headerName: this.$t("View"),
          field: "contact",
          cellRenderer: "CellRendererActions",
          width: 90,
        },
        {
          headerName: "Data",
          field: "createdAt",
          width: 130,
        },
        {
          headerName: this.$t("Name"),
          field: "name",
          width: 180,
        },
        {
          headerName: this.$t("Email"),
          field: "email",
          width: 200,
        },
        {
          headerName: this.$t("Phone"),
          field: "phone",
          width: 180,
        },
        {
          headerName: "Tipo",
          field: "type",
          width: 110,
        },
        {
          headerName: "Status",
          field: "phase",
          width: 100,
        },
        {
          headerName: "Tags",
          field: "tags",
          cellRenderer: "CellRendererTags",
          cellRendererParams: (params) => {
            return params.data.tags;
          },
          width: 240,
        },
      ],
      localeText:
        this.$store.state.user.lang === "pt_BR" ||
        this.$store.state.user.lang === "pt-br"
          ? localeTextPT_br.localeText
          : "en",
      monthNames: [
        "Todos",
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novembro",
        "Dezembro",
      ],
      monthFilter: null,
      currentYearIndex: null,
      disabledDateAndCsv: true,
      showLoadMoreContactsObject: {},
      showLoadMoreContacts: false,
      loadMoreContactsLoading: false,
      blocked: false,
    };
  },
  watch: {
    blocked() {
      this.getContacts();
    },
    optOut() {
      this.getContacts();
    },
    showContact(val) {
      if (val === "table") {
        this.phone = true;
        this.email = true;
        this.getContacts(null, this.monthFilter);
      } else if (val === "board") {
        this.phone = false;
        this.email = false;
      }
    },
    phone(val) {
      this.changeColumns("phone", val);
    },
    channel(val) {
      this.changeColumns("channel", val);
    },
    email(val) {
      this.changeColumns("email", val);
    },
    type(val) {
      this.changeColumns("type", val);
    },
    date(val) {
      this.changeColumns("date", val);
    },
    currentPage() {
      this.getContacts(null, this.monthFilter);
    },
    status(val) {
      this.changeColumns("status", val);
    },
    tags(val) {
      this.changeColumns("tags", val);
    },
    visibleColumns(val) {
      this.columnDefs = [];
      for (let i in val) {
        if (val[i] === "view")
          this.columnDefs.push({
            headerName: this.$t("View"),
            field: "contact",
            cellRenderer: "CellRendererActions",
            width: 80,
          });
        if (val[i] === "channel")
          this.columnDefs.push({
            headerName: "Channel",
            field: "contact.channel",
            cellRenderer: "CellRendererChannel",
            width: 110,
          });
        if (val[i] === "name")
          this.columnDefs.push({
            headerName: this.$t("Name"),
            field: "name",
            width: 180,
          });
        if (val[i] === "email")
          this.columnDefs.push({
            headerName: this.$t("Email"),
            field: "contact.email",
            width: 220,
          });
        if (val[i] === "phone")
          this.columnDefs.push({
            headerName: this.$t("Phone"),
            field: "contact.phone",
            width: 180,
          });
        if (val[i] === "type")
          this.columnDefs.push({
            headerName: "Tipo",
            field: "type",
            width: 110,
          });
        if (val[i] === "date")
          this.columnDefs.push({
            headerName: this.$t("Data"),
            field: "created",
            width: 130,
          });
        if (val[i] === "status")
          this.columnDefs.push({
            headerName: "Status",
            field: "statusContact",
            width: 100,
          });
        if (val[i] === "tags")
          this.columnDefs.push({
            headerName: "Tags",
            field: "contact.tags",
            cellRenderer: "CellRendererTags",
            width: 240,
          });
      }
    },
    phaseFilter(val) {
      if (this.showContact === "board") {
        if (val.value === "all") this.visiblePhases = this.phase;
        else this.visiblePhases = [val.value];
      } else this.getContacts(null, this.monthFilter);
    },
    typeFilter(val) {
      this.typeFilterBoard = val.value;
      this.getContacts(null, this.monthFilter);
    },
    monthFilter() {
      this.getContacts(null, this.monthFilter);
    },
    currentYearIndex() {
      this.monthFilter = null;
      this.getContacts(null, this.monthFilter);
    },
    boardSearch(val) {
      if (val) {
        if (this.$refs.boardCol)
          this.$refs.boardCol.forEach((el) => {
            el.resetSearch(
              this.typeFilter.value,
              this.monthFilter,
              this.years[this.currentYearIndex]
            );
          });
        this.boardSearch = false;
      }
    },
  },
  computed: {
    ...mapState("contact", ["activeProfileSidebar", "phase", "count"]),
    years() {
      let years = ["Todos"];
      for (let y = 2020; y <= new Date().getFullYear(); y++) {
        years.push(y);
      }

      return years;
    },
    totalPages() {
      return this.count > 0 ? Math.ceil(this.count / this.limit) : 0;
    },
    contacts() {
      if (this.showContact === "board")
        return this.$store.state.contact.contactsPhase;
      else
        return this.$store.state.contact.contactsPhase.filter((element) => {
          if (this.blocked) {
            return (
              this.visiblePhases.indexOf(element.phase) > -1 &&
              element.status === -1
            );
          } else {
            return this.visiblePhases.indexOf(element.phase) > -1;
          }
        });
    },
    contactsPhase() {
      return this.$store.state.contact.contactsPhase;
    },
    visiblePhasesOptions: {
      get: function () {
        if (this.visiblePhases.length === 0) {
          this.phase.forEach((element) => {
            this.visiblePhases.push(element);
          });
        }
        return this.visiblePhases;
      },
      set: function (value) {
        this.visiblePhases = value;
      },
    },
  },
  methods: {
    getContacts() {
      if (this.monthFilter === null) {
        this.disabledDateAndCsv = true;
      } else {
        this.disabledDateAndCsv = false;
        this.monthFilter = this.monthFilter ? this.monthFilter : null;
      }

      this.$store.dispatch("contact/resetContactsPhase");

      if (this.showContact === "board") {
        this.boardSearch = true;
      } else {
        this.showAgGridLoading();
        this.$store.dispatch("contact/getContactsBoard", {
          acc: this.$store.state.acc.current_acc.id,
          limit: this.limit,
          page: this.currentPage,
          phase: this.phaseFilter.value,
          search: normalizeString(this.nameFilter),
          type: this.typeFilter.value,
          tags: this.tagsFilter.length > 0 ? this.tagsFilter : null,
          monthFilter: this.monthFilter,
          currentYear: this.years[this.currentYearIndex],
          blocked: this.blocked,
          optOut: this.optOut,
        });
      }
    },
    updateCountBoard(count) {
      this.$set(this.countBoard, count.phase, count.count);
    },
    resetSearch() {
      this.boardSearch = false;
    },
    closeSidebar() {
      this.sidebar = false;
      this.contact = {};
    },
    showSidebar(contact) {
      this.contact = contact;
      this.sidebar = true;
    },
    downloadCSV() {
      window.analytics.track(
        "Download Contatos",
        {},
        { groupId: this.$store.state.acc.current_acc.id }
      );
      const acc = this.$store.state.acc.current_acc.id;
      const name = encodeURI(this.nameFilter);
      const emailOrPhone = encodeURI(this.nameFilter);
      const tags = encodeURI(this.tagsFilter);

      const monthFilter = this.monthFilter;
      const currentYear = this.years[this.currentYearIndex];

      window.open(
        `${process.env.VUE_APP_API_URL}p/chat/contact/downloadcsv/?acc=${acc}&name=${name}&emailOrPhone=${emailOrPhone}&tags=${tags}&monthFilter=${monthFilter}&currentYear=${currentYear}`,
        "_blank"
      );
    },
    updateContact(update, oldPhase) {
      let contacts = this.$store.state.contact.contacts;

      if (this.showContact === "board")
        contacts = this.$store.state.contact.contactsPhase;
      const contact = Object.assign(
        {},
        contacts.find((contact) => contact._id === update._id)
      );

      let index = contacts.findIndex((el) => el._id === update._id);
      update.index = index;
      this.$store.dispatch("contact/updateContactProp", update).then(() => {
        if (update.prop === "phase") {
          contact.phase = oldPhase;
          let boardColIndex = this.phase.findIndex(
            (phase) => phase === contact.phase
          );
          this.$refs.boardCol[boardColIndex].changeList(
            { removed: { element: contact } },
            true
          );
          contact.phase = update.value;
          boardColIndex = this.phase.findIndex(
            (phase) => phase === contact.phase
          );
          this.$refs.boardCol[boardColIndex].changeList(
            { added: { element: contact } },
            true
          );
          this.contact = Object.assign({}, contact);
        }
        this.$store.dispatch("contact/refreshContact", {
          index: index,
          contactRef: this.showContact,
        });
      });
    },
    changeColumns(column, visible) {
      let index =
        this.showContact === "table"
          ? this.visibleColumns.indexOf(column)
          : this.fieldsBoard.indexOf(column);
      if (!visible) {
        if (index !== -1) {
          this.showContact === "table"
            ? this.visibleColumns.splice(index, 1)
            : this.fieldsBoard.splice(index, 1);
        }
      } else {
        if (index === -1) {
          this.showContact === "table"
            ? this.visibleColumns.push(column)
            : this.fieldsBoard.push(column);
        }
      }
    },
    setColumnFilter(column, val) {
      const filter = this.gridApi.getFilterInstance(column);
      let modelObj = null;

      if (val !== "all") {
        modelObj = { type: "equals", filter: val };
      }

      filter.setModel(modelObj);
      this.gridApi.onFilterChanged();
    },
    getCountBoard(num) {
      return num < 10 ? `0${num}` : num;
    },
    showAgGridLoading() {
      if (this.gridApi) this.gridApi.showLoadingOverlay();
    },
    loadMoreContacts() {
      this.loadMoreContactsLoading = true;
      this.phase.forEach((phase, index) => {
        if (
          this.countBoard[phase] >
          this.$refs.boardCol[index].contactsFiltered.length
        ) {
          this.$refs.boardCol[index].loadMoreContacts();
        }
      });
    },
    setShowLoadMoreContacts(val, phaseContact) {
      this.showLoadMoreContactsObject[phaseContact] = !!val;

      let haveTrue = false;
      Object.values(this.showLoadMoreContactsObject).forEach((showLoad) => {
        if (showLoad) haveTrue = true;
      });
      this.showLoadMoreContacts = haveTrue;
    },
    setLoadMoreContactsLoading(val) {
      this.loadMoreContactsLoading = !!val;
    },
    initAddConversationPopup({ name, phone, email }) {
      this.$router.push({
        path: "/apps/inbox/start-conversation",
        query: { name, phone, email },
      });
    },
  },
  beforeMount() {
    this.overlayLoadingTemplate =
      '<div class="ag-overlay-loading-center"> <svg class="animate-spin" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"> <path d="M12 22C6.5 22 2 17.5 2 12S6.5 2 12 2s10 4.5 10 10" /> </svg> </div>';
  },
  mounted() {
    this.currentPage = 0;
    this.gridApi = this.gridOptions.api;
    if (this.$vs.rtl) {
      const header = this.$refs.agGridTable.$el.querySelector(
        ".ag-header-container"
      );
      header.style.left =
        "-" + String(Number(header.style.transform.slice(11, -3)) + 9) + "px";
    }
  },
  created() {
    if (
      this.$store.state.acc.current_acc.id === "65048a74739c267142452d53" &&
      this.$store.state.user.userRole === "operator"
    ) {
      this.$router.push({ name: "dashboard" });
      this.$vs.dialog({
        color: "warning",
        title: "Oops",
        text: this.$t("YouDontHaveAccess"),
        accept: () => {},
        cancel: () => {},
        acceptText: "Ok",
      });
      this.$vs.loading.close();
      return;
    }
    this.$store.dispatch("contact/getPhases", {
      acc: this.$store.state.acc.current_acc.id,
    });
  },
};
</script>
<style lang="scss">
#sidebar-contact > .vs-content-sidebar > .vs-sidebar {
  max-width: 350px !important;
}
#board-card {
  width: auto;
  max-height: 200px;
  white-space: nowrap;
  flex: 1 1 auto;
}
#board-card-wrapper {
  width: auto;
  border: 1rem solid #ffffff;
  background-color: #ffffff;
  white-space: nowrap;
  flex: 1 1 auto;
}
#board-header {
  width: 100%;
  z-index: 50;
  background-color: #ffffff;
  height: 50px;
  padding: 1rem;
  top: 0;
  margin: -10px 0px 0px -10px;
  div {
    min-width: 334px;
    width: 334px;
  }
  -webkit-box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.39);
  box-shadow: 0 1px 3px -2px rgba(0, 0, 0, 0.39);
}
.count-board {
  margin-top: -2px;
  margin-right: 5px;
  border-radius: 50%;
  padding: 4px;
  font-size: 10px;
  font-weight: bold;
  background: rgba(var(--vs-primary), 1) !important;
  color: #fff;
  text-align: center;
}
#sidebar-contact {
  position: relative;
}
.icon-type-board {
  position: relative;
  top: 61px;
  right: 44px;
  z-index: 100;
}
.icon-ag-grid {
  position: absolute;
  z-index: 100;
  right: 12px;
  top: 10px;
}
.icon-board {
  position: absolute !important;
  z-index: 100;
  right: 42px;
  top: 10px;
}
.column-width {
  min-width: 320px;
  width: 320px;
}
</style>
